export function pageTop() {
  let pageTop =  document.querySelector('.js-pagetop');

  pageTop.addEventListener( 'click' , scrollTop );

  function scrollTop(){
    window.scroll({top: 0, behavior: 'smooth'});
  }

  let Animation = function() {

    if(window.pageYOffset >450){
      pageTop.classList.remove('is-hidden');
    } else {
      pageTop.classList.add('is-hidden');
    }
  }
  window.addEventListener('scroll', Animation);
}