import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

export function initSlider() {
  if (document.querySelector('.js-gravestone-slider')) {
    const gravestoneSwiper = new Swiper('.js-gravestone-slider', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      speed: 2000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
    });
  }
}


