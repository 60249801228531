import '../scss/styles.scss';

import * as animation from './modules/animation';
import {pageTop} from "./modules/pagetop";
import {drawerToggle} from "./modules/drawerToggle";
import {smoothScroll} from "./modules/smoothScroll";
import {initSlider} from "./modules/slider.js";

const loading = document.querySelector( '.loading' );

window.addEventListener('load', () => {
  loading.classList.add( 'hide' );
  animation.scrollObserver();
  drawerToggle();
  smoothScroll();
  initSlider();
}, false )

window.addEventListener('scroll', () => {
  pageTop();
}, false )
